<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <span class="search-box-title">兑换账号：</span>
        <el-input v-model="search.exchange_account" placeholder="请输入兑换账号" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">兑换码：</span>
        <el-input v-model="search.exchange_code" placeholder="请输入兑换码" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">状态：</span>
        <el-select v-model="search.status" filterable placeholder="全部" size="medium" clearable>
          <el-option :key="-1" label="全部" :value="-1"></el-option>
          <el-option :key="0" label="未领取" :value="0"></el-option>
          <el-option :key="1" label="已领取" :value="1"></el-option>
          <el-option :key="2" label="已过期" :value="2"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <span class="search-box-title">群集ID：</span>
        <el-input v-model="search.cluster_id" placeholder="请输入群集ID" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="getList()">查询</el-button>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="dialogAdd = true">生成兑换码</el-button>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="dialogExport = true">导出兑换码</el-button>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{ background: '#F7F8FA' }">
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="code" label="兑换码" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="type" label="兑换类型" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="param" label="兑换详情" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="create_time" label="创建时间" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="create_by" label="创建人" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="exchange_time" label="兑换时间" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="exchange_by" label="兑换人" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="cluster_id" label="群集ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="status" label="状态" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <div :style="{color: scope.row.status == '正常' ? '#33C933' : '#F33333' }">{{scope.row.status}}</div>
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />

    <!-- 生成兑换码 -->
    <el-dialog custom-class="dialog" title="生成兑换码" v-model="dialogAdd" width="700px" top="35vh" :close-on-click-modal="false" @close="closeDialogAdd">
      <div class="form_box2">
        <div class="form_title2">生成兑换码：</div>
        <el-input v-model="addArr.create_num" placeholder="生成兑换码" size="small"></el-input>
        （不得超过2000个）
      </div>
      <div class="form_box2">
        <div class="form_title2">兑换类型：</div>
        <el-select v-model="addArr.type" filterable placeholder="全部" size="medium" clearable>
          <el-option :key="1" label="VIP" :value="1"></el-option>
        </el-select>
      </div>
      <div class="form_box2">
        <div class="form_title2">兑换详情：</div>
        <el-input v-model="addArr.param" placeholder="兑换详情" size="small"></el-input>
        如果兑换类型是VIP，则输入天数，9999为永久
      </div>
      <div class="form_box2">
        <div class="form_title2">兑换有效期：</div>
        <el-input v-model="addArr.expire" placeholder="兑换有效期" size="small"></el-input>
        输入天数，9999为永久有效
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="createCode">开始创建</el-button>
          <el-button type="primary" @click="dialogAdd = false">取消</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 生成兑换码成功 -->
    <el-dialog custom-class="dialog" title="生成兑换码成功" v-model="dialogText" width="492px" top="35vh" :close-on-click-modal="false" @close="closeDialogText">
      <div class="form_box">
        成功创建{{dialogTextArr.create_num}}个兑换码！此批次兑换码集群ID为：[{{dialogTextArr.cluster_id}}] 如需导出请复制群集ID并前往导出兑换界面进行导出
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="dialogText = false">确定</el-button>
          <el-button type="primary" @click="dialogText = false;dialogExport = true">前往导出</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 导出兑换码 -->
    <el-dialog custom-class="dialog" title="导出兑换码" v-model="dialogExport" width="492px" top="35vh" :close-on-click-modal="false" @close="closeDialogExport">
      <div class="form_box">
        <div class="form_title">兑换码集群ID：</div>
        <el-input v-model="exportArr.cluster_id" placeholder="请输入兑换码集群ID" size="small"></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="exportCode">开始导出</el-button>
          <el-button type="primary" @click="dialogExport = false">取消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getList, createCode, exportCode } from "@/api/users/exchangeLog";
import page from "@/components/page";
export default {
  name: "userList",
  components: {
    page,
  },
  data() {
    return {
      pager: { total: 0, page: 1, rows: 10 },
      search: {
        //搜索
        exchange_account: "", // 兑换账号
        exchange_code: "", // 兑换码
        status: -1, // 状态
        cluster_id: "", // 群集ID
      },
      tableData: [{}], //数据
      row: [], //选中的数据
      dialogAdd: false,
      addArr: {
        create_num: 0,
        type: 1,
        param: "",
        expire: "",
      },
      dialogExport: false,
      exportArr: {
        cluster_id: "",
      },
      dialogText: false,
      dialogTextArr: {
        cluster_id: "x",
        create_num: 0,
      },
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    //获取数据
    getList() {
      getList([
        { key: "page", val: this.pager.page },
        { key: "row", val: this.pager.rows },
        { key: "exchange_account", val: this.search.exchange_account },
        { key: "exchange_code", val: this.search.exchange_code },
        { key: "status", val: this.search.status },
        { key: "cluster_id", val: this.search.cluster_id || 0 },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.pager.total = res.data.total;
            this.tableData = res.data.list;
          } else {
            this.$message({ message: res.data, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 生成兑换码
    createCode() {
      // 基础校验
      const addArr = this.addArr;
      if( !addArr.create_num || addArr.create_num <= 0 || addArr.create_num > 2000){
        return this.$message({message:"生成数量错误，请确认生成数量是否正确", type: "error" });
      }
      if( !addArr.type ){
        return this.$message({message:"兑换类型错误，兑换类型不能为空", type: "error" });
      }
      if( !addArr.param || addArr.create_num <= 0 || addArr.create_num > 9999){
        return this.$message({message:"兑换详情错误，请确认兑换详情是否正确", type: "error" });
      }
      if( !addArr.expire || addArr.create_num <= 0 || addArr.create_num > 9999){
        return this.$message({message:"兑换有效期错误，请确认兑换有效期是否正确", type: "error" });
      }
      createCode([
        { key: "create_num", val: this.addArr.create_num },
        { key: "type", val: this.addArr.type },
        { key: "param", val: this.addArr.param },
        { key: "expire", val: this.addArr.expire },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.dialogAdd = false;
            this.dialogTextArr.cluster_id = res.data.cluster_id;
            this.dialogTextArr.create_num = res.data.create_num;
            this.exportArr.cluster_id = res.data.cluster_id;
            this.dialogText = true;
            this.getList();
          } else {
            this.$message({ message: res.data, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 导出兑换码
    exportCode() {
      if( !this.exportArr.cluster_id ){
        return this.$message({message:"兑换码集群ID错误，请确认兑换码集群ID是否正确", type: "error" });
      }
      exportCode([{ key: "cluster_id", val: this.exportArr.cluster_id }])
        .then((res) => {
          if (res.code == 0) {
            window.location.href = res.data.path;
          } else {
            this.$message({ message: res.data, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 清空
    closeDialogAdd() {
      this.addArr = {
        create_num: 0,
        type: 1,
        param: "",
        expire: "",
      };
    },
    closeDialogText() {
      this.dialogTextArr = { cluster_id: "x", create_num: 0 };
    },
    closeDialogExport() {
      this.exportArr = {
        cluster_id: "",
      };
    },
  },
};
</script>

<style scoped>
.form_box,
.form_box2 {
  overflow: hidden;
  margin-bottom: 18px;
}
.form_title {
  width: 30%;
  float: left;
  height: 32px;
  line-height: 32px;
}
.form_box >>> .el-input,
.form_box >>> .el-textarea {
  width: 60%;
}
.form_box >>> .el-select .el-input {
  width: 100%;
}
.form_title2 {
  width: 20%;
  float: left;
  height: 32px;
  line-height: 32px;
}
.form_box2 >>> .el-input,
.form_box2 >>> .el-textarea {
  width: 30%;
}
.form_box2 >>> .el-select .el-input {
  width: 100%;
}
</style>
